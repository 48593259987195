
import { checkPostPassword, deletePost, deleteSecretPost } from '@/api/post';
import { UserModule } from '@/store/modules/user';
import { IBoard, IPost } from '@/types';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import { likePost } from '@/api/postLike';

@Component({
  name: 'BoardDefaultDetail',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private board!: any

  @Prop({ required: true }) private post!: any

  @Watch('post', { deep: true })
  private handleChangePost() {
    this.likeStatus = this.post.likeStatus;
    this.likeCount = this.post.likeCount;
  }

  private passwordCheckVisible = false;

  private commonApiUrl = process.env.VUE_APP_COMMON_API;

  private mode = '';

  private password = '';

  private likeStatus = false;

  private likeCount = 0;

  private commentForm = {
    contents: '',
  }

  private subCommentForm = {
    contents: '',
  }

  private commentList = [
    {
      uid: 'sdfg',
      writer: 'abcd***e',
      contents: '댓글내용댓글내용1',
      createDate: '2020-06-24 22:57:36',
      recommenNum: 3,
      childView: true,
      info: false,
      comments: [
        {
          uid: 'sadfagdf',
          writer: 'abcd***e',
          contents: '답글내용답글내용답글내용1',
          createDate: '2022-07-21 16:19',
          recommenNum: 0,
          info: false,
        },
        {
          uid: 'dfahwedf',
          writer: 'abcd***e',
          contents: '답글내용답글내용답글내용1',
          createDate: '2022-07-21 16:19',
          recommenNum: 0,
          info: false,
        },
      ],
    },
    {
      uid: 'dasgadfwedf',
      writer: 'abcd***e',
      contents: '댓글내용댓글내용1',
      createDate: '2022-07-21 16:19',
      recommenNum: 3,
      childView: false,
      info: false,
      comments: [
        {
          uid: 'sadfagasgaedf',
          writer: 'abcd***e',
          contents: '답글내용답글내용답글내용1',
          createDate: '2022-07-21 16:19',
          recommenNum: 0,
          info: false,
        },
        {
          uid: 'dfahwehsaegdf',
          writer: 'abcd***e',
          contents: '답글내용답글내용답글내용1',
          createDate: '2022-07-21 16:19',
          recommenNum: 0,
          info: false,
        },
      ],
    },
  ];

  private handleCommentBox(index: number) {
    this.commentList[index].childView = !this.commentList[index].childView;
  }

  private handleAmendBox(index: number, subIndex: number) {
    if (subIndex === undefined) this.commentList[index].info = !this.commentList[index].info;
    else this.commentList[index].comments[subIndex].info = !this.commentList[index].comments[subIndex].info;
  }

  get isAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private handleClickDelete() {
    if (this.board.authWrite === 'GUEST' && !this.isAdmin) {
      this.passwordCheckVisible = true;
      this.mode = 'delete';
      return;
    }
    this.$confirm('정말 게시글을 삭제하시겠습니까?').then(() => {
      deletePost(this.$route.params.postUid).then(() => {
        this.$message.success('게시글이 삭제되었습니다.');
        this.$router.push({ name: 'BoardIndex', params: { boardUid: this.$route.params.boardUid } });
      }).catch((error) => {
        this.$message.error(error.response.data.message || '게시글을 삭제하는데 실패했습니다.');
      });
    });
  }

  private handleClickUpdate() {
    if (this.board.authWrite === 'GUEST' && !this.isAdmin) {
      this.passwordCheckVisible = true;
      this.mode = 'update';
    } else {
      this.$router.push({
        name: 'PostUpdate',
        params: {
          boardUid: this.$route.params.boardUid,
          postUid: this.$route.params.postUid,
        },
      });
    }
  }

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (this.board.authWrite === 'GUEST') return true;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }

  private handleList() {
    this.$router.push({
      name: 'BoardIndex',
      params: { boardUid: this.$route.params.boardUid },
      query: { ...this.$route.query },
    });
  }

  private handleLikePost() {
    if (UserModule.isLogin) {
      likePost(this.$route.params.postUid).then(() => {
        this.likeStatus = !this.likeStatus;
        if (this.likeStatus) this.likeCount += 1;
        else this.likeCount -= 1;
      });
    } else {
      this.$router.push({ name: 'Login' });
    }
  }
}
