
import { checkPostPassword, deletePost, deleteSecretPost } from '@/api/post';
import { UserModule } from '@/store/modules/user';
import { IBoard, IPost } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';

@Component({
  components: {
  },
})
export default class extends Vue {
  @Prop({ required: true }) private board!: IBoard

  @Prop({ required: true }) private post!: IPost

  private passwordCheckVisible = false;

  private mode = '';

  private password = '';

  get isAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private handleClickDelete() {
    if (this.board.authWrite === 'GUEST' && !this.isAdmin) {
      this.passwordCheckVisible = true;
      this.mode = 'delete';
      return;
    }
    this.$confirm('정말 게시글을 삭제하시겠습니까?').then(() => {
      deletePost(this.$route.params.postUid).then(() => {
        this.$message.success('게시글이 삭제되었습니다.');
        this.$router.push({ name: 'BoardIndex', params: { boardUid: this.$route.params.boardUid } });
      }).catch((error) => {
        this.$message.error(error.response.data.message || '게시글을 삭제하는데 실패했습니다.');
      });
    });
  }

  private handleClickUpdate() {
    if (this.board.authWrite === 'GUEST' && !this.isAdmin) {
      this.passwordCheckVisible = true;
      this.mode = 'update';
    } else {
      this.$router.push({
        name: 'PostUpdate',
        params: {
          boardUid: this.$route.params.boardUid,
          postUid: this.$route.params.postUid,
        },
      }).catch(() => {
        // do nothing.
      });
    }
  }

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (this.board.authWrite === 'GUEST') return true;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }

  private handleList() {
    this.$router.push({
      name: 'BoardIndex',
      params: { boardUid: this.$route.params.boardUid },
      query: { ...this.$route.query },
    });
  }
}
